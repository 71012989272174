import React, { Component } from 'react'
import { Button, Form, Grid, Image} from 'semantic-ui-react'

// function postAjax(url,data,success){
//     var xhr = new XMLHttpRequest();
//     xhr.open('POST',url,true);
//     xhr.onreadystatechange = function(){
//       alert(xhr.status)
//       if (xhr.readyState>3 && xhr.status==200){success(xhr.responseText);}};
//     xhr.setRequestHeader("Content-type","application/x-www-form-urlencoded");
//     xhr.send(data);
//     return xhr;
// }

class LoginForm extends Component {
  state         = {
    email     : '',
    password  : '',
    submit    : 'Login',
    color     : 'blue'}
  handleSuccess = (message) => {
    if(message.slice(0,8)=="https://"){
      window.location.href = message
    }else{
      this.setState({
      email     :'',
      password  :'',
      submit    :message,
      color     :'red'})
    }
  }
  xhr           = new XMLHttpRequest()
  postAjax      = (url,data) => {
    this.xhr.open('POST',url,true)
    this.xhr.onreadystatechange = () => {
      if (this.xhr.readyState>3 && this.xhr.status==200){
        this.handleSuccess(this.xhr.responseText)
      }
    }
    this.xhr.setRequestHeader("Content-type","application/x-www-form-urlencoded")
    this.xhr.send(data);
  }
  handleChange    = (e,{name,value}) => {this.setState({[name]:value})}
  handleClick     = (e) => {
    this.setState({
      email     :this.email,
      password  :this.password,
      submit    :'Login',
      color     :'blue'})  
  }
  handleSubmit    = () => {
    const {email,password,submit,color} = this.state
    if(password!=""){
      // postAjax('http://www.selfsimilarsolutions.com/assets/check_access.php',"pwrd="+password,this.handleSuccess)
      this.postAjax('http://www.selfsimilarsolutions.com/assets/check_access.php',"pwrd="+password)
    }
    this.setState({
      email     :'',
      password  :'',
      submit    :'Login Failed',
      color     :'red'})
  }
  render() {
    const {email,password,submit,color} = this.state
    return (
      <div className='login-form'>
        {/*
          Heads up! The styles below are necessary for the correct render of this example.
          You can do same with CSS, the main idea is that all the elements up to the `Grid`
          below must have a height of 100%.
        */}
        <style>{`
          body > div,
          body > div > div,
          body > div > div > div.login-form {
            height: 100%;
          }
        `}</style>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Form size='large' onSubmit={this.handleSubmit}>
              <Form.Field massive>
                <Image src='img/S3_logo_on_blue.svg' fluid/> 
              </Form.Field>
              <Form.Input 
                fluid 
                name          ='email' 
                placeholder   ='Email'
                type          ='email' 
                // icon          ='user' 
                // iconPosition  ='left' 
                value         ={email} 
                onClick       ={this.handleClick}
                onChange      ={this.handleChange}/>
              <Form.Input
                fluid
                name          ='password'
                placeholder   ='Password'
                // icon          ='lock'
                // iconPosition  ='left'
                type          ='password'
                value         ={password}
                onClick       ={this.handleClick}
                onChange      ={this.handleChange}/>
              <Form.Button 
                fluid 
                name          ='submit'
                color         ={color} 
                size          ='large' 
                // icon          ='sign-in'
                // iconPosition  ='right'
                content       ={submit}/>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}
export default LoginForm